export default {
  'menu.home': '首页',
  'menu.dashboard': 'Dashboard',
  'menu.dashboard.analysis': '分析页',
  'menu.dashboard.monitor': '监控页',
  'menu.dashboard.workplace': '工作台',
  'menu.form': '表单页',
  'menu.form.basicform': '基础表单',
  'menu.form.stepform': '分步表单',
  'menu.form.stepform.info': '分步表单（填写转账信息）',
  'menu.form.stepform.confirm': '分步表单（确认转账信息）',
  'menu.form.stepform.result': '分步表单（完成）',
  'menu.form.advancedform': '高级表单',
  'menu.list': '列表页',
  'menu.list.searchtable': '查询表格',
  'menu.list.basiclist': '标准列表',
  'menu.list.cardlist': '卡片列表',
  'menu.list.searchlist': '搜索列表',
  'menu.list.searchlist.articles': '搜索列表（文章）',
  'menu.list.searchlist.projects': '搜索列表（项目）',
  'menu.list.searchlist.applications': '搜索列表（应用）',
  'menu.profile': '详情页',
  'menu.profile.basic': '基础详情页',
  'menu.profile.advanced': '高级详情页',
  'menu.result': '结果页',
  'menu.result.success': '成功页',
  'menu.result.fail': '失败页',
  'menu.exception': '异常页',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': '触发错误',
  'menu.account': '个人设置',
  'menu.account.center': '商户配置',
  'menu.account.settings': '商户基本信息',
  'menu.account.subaccounts': '子商户管理',
  'menu.account.domains': '域名管理',
  'menu.account.trigger': '触发报错',
  'menu.account.logout': '退出登陆',
  'menu.merchant': '售后管理',
  'menu.merchant.order': '商户订单',
  'menu.merchant.gateway': 'Paypal账号',
  'menu.merchant.developer': '商户网关池',
  'menu.merchant.origin_payment': '订单',
  'menu.merchant.dispute': '争议中心',
  'menu.merchant.Xborder': 'Xborder工单',
  'menu.setting.customerService': '客服管理',
  'menu.merchant.export': '导出任务',
  'menu.merchant.audit': '用户审计',
  'menu.merchant.emailCenter': '邮件中心',
  'menu.merchant.orderCenter': '订单中心',
  'menu.merchant.orderOutline': '订单概要',
  'menu.merchant.orderDetails': '订单详情',
  'menu.merchant.orderDetailsEC': 'EC订单详情',
  'menu.operation.refund': '物流退款',
  'menu.merchant.transaction': '交易记录',
  'menu.merchant.transactionDetails': '交易详情',
  'menu.merchant.transactionDetailsEC': 'EC交易详情',
  'menu.merchant.transactionDetailsEn': 'Transaction details',
  'menu.merchant.Activity': 'Activity',
  'menu.merchant.track_info': '物流单号',
  'menu.dailyReport': '每日报告',
  'menu.dailyReport.auditReport': '审计报告下载',
  'menu.dailyReport.transactionReport': '交易记录下载',
  'menu.dailyReport.uploadReport': '交易记录上传',
  'menu.dailyReport.disputeReport': '争议和退单下载',

  'menu.person': '个人设置',
  'menu.person.usermessage': '个人信息',
  'menu.person.userpwd': '密码',

  'menu.operation': '运营管理',
  'menu.operation.orderExport': '订单导出',
  'menu.operation.ExportTask': '运营导出',
  'menu.operation.successRate': '信用卡通道(1)成功率',
  'menu.operation.collectionreport': '收款交易报告',

  'menu.manage': '争议管理',
  'menu.manage.dispute': '争议中心',
  'menu.manage.disputeDetails': '争议详情',
  'menu.manage.Xborder': '信用卡通道(1)工单',
  'menu.manage.chargeback': '信用卡通道(1)拒付',
  'menu.manage.saas': 'Saas争议',

  'menu.template': '模板管理',
  'menu.template.paypal': 'Paypal模板管理',
  'menu.template.email': '邮件模板管理',
  'menu.template.keyword': '信用卡通道(1)关键词',
  'menu.template.xborder': '信用卡通道(1)模板管理',

  'menu.setting': '系统设置',
  'menu.setting.role': '角色管理',

  'menu.merchant.sub': 'PP子账号',
  'menu.merchant.code': '谷歌验证码',

  'menu.manage.evidence': '证据管理',
  'menu.merchant.label': '退款待打标签',

  'menu.disputeAnalyse': '争议分析',
  'menu.disputeAnalyse.store': '店铺争议',
  'menu.disputeAnalyse.sku': 'SKU争议',
  'menu.disputeAnalyse.family': 'PP家族争议',
  'menu.disputeAnalyse.team': '团队争议',
  'menu.email': '邮件中心',
  'menu.email.criticalMails': '关键邮件管理',
  'menu.email.emailRule': '邮件自动化规则',
};
